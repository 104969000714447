<template>
  <q-page padding>
    <div class="row">
      <q-table
        title="Frase"
        :rows="phrases"
        :columns="columnsPhrases"
        row-key="id"
        class="col-12"
        :loading="loading"
      >
        <template v-slot:top>
          <span class="text-h6">Frase</span>
          <q-space />
          <q-btn
            v-if="$q.platform.is.desktop"
            dense
            icon="add"
            alt="Adicionar frase"
            color="positive"
            size="sm"
            :to="{name: 'form-phrase'}"
          >
            <q-tooltip>
              Adicionar frase
            </q-tooltip>
          </q-btn>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-md">
              <q-btn
                icon="edit"
                alt="Editar"
                color="info"
                dense
                size="sm"
                @click="handleEditPhrase(props.row)"
              >
                <q-tooltip>
                  Editar frase
                </q-tooltip>
              </q-btn>
              <q-btn
                icon="delete"
                alt="Remover"
                color="negative"
                dense
                size="sm"
                @click="handleRemovePhrase(props.row)"
              >
                <q-tooltip>
                  Remover frase
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </div>
    <q-page-sticky
      position="bottom-right"
      :offset="[18, 18]"
      v-if="$q.platform.is.mobile"
    >
      <q-btn
        fab
        icon="add"
        color="positive"
        padding="md"
        :to="{name: 'form-phrase'}"
      >
        <q-tooltip>
          Adicionar frase
        </q-tooltip>
      </q-btn>
    </q-page-sticky>
  </q-page>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import useApi from '../../composables/UseApi'
import useNotify from '../../composables/UseNotify'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { columnsPhrases } from './table'

export default defineComponent({
  name: 'PageListAutores',

  setup () {
    const table = 'Frase'
    const phrases = ref([])
    const router = useRouter()
    const { list, remove } = useApi()
    const { notifyError, notifySuccess } = useNotify()
    const loading = ref(true)
    const $q = useQuasar()

    const handleListPhrases = async () => {
      try {
        loading.value = true
        phrases.value = await list(table)
        loading.value = false
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleEditPhrase = (phrase) => {
      router.push({ name: 'form-phrase', params: { id: phrase.id } })
    }

    const handleRemovePhrase = async (phrase) => {
      try {
        $q.dialog({
          title: 'Confirme',
          message: `Você realmente deseja remover  ${phrase.name}?`,
          cancel: true,
          persistent: true
        }).onOk(async () => {
          try {
            await remove(table, phrase.id)
            notifySuccess('Frase removida com sucesso!')
            handleListPhrases()
          } catch (error) {
            notifyError(error.message)
          }
        })
      } catch (error) {
        notifyError(error.message)
      }
    }

    onMounted(async () => {
      handleListPhrases()
    })

    return { columnsPhrases, phrases, loading, handleEditPhrase, handleRemovePhrase }
  }
})
</script>
