const columnsPhrases = [
  { name: 'description', align: 'left', label: 'Descrição', field: 'description', sortable: true },
  { name: 'hashtags', align: 'left', label: 'Hashtags', field: 'hashtags', sortable: true },
  { name: 'actions', label: 'Ações', field: 'actions', sortable: false }
]

const hashtags = ['sofrases', 'inspiração', 'inspiration', 'motivação', 'motivation', 'frases', 'versos', 'amor', 'amizade', 'reflexão']

export {
  columnsPhrases,
  hashtags
}
